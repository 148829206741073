<template>
  <div class="machine-list">
    <div class="table-list-layout">
      <div class="table-list">
        <div class="header">
          <Breadcrumb>
            <BreadcrumbItem to="/dmmachine">{{ $t('cha-xun-ji-qi') }}
            </BreadcrumbItem>
            <BreadcrumbItem>{{ $t('ji-qi-lie-biao') }}</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div class="content">
          <div class="option">
            <div class="left">
              <div class="machine">
                <div class="circle">
                  <cc-iconfont name="machine" size="16"/>
                </div>
                <div class="content">
                  <div class="first">
                    <div>{{ cluster.clusterDesc }}</div>
                    <div>{{ cluster.clusterName }}</div>
                  </div>
                  <div class="second">
                    <div>
                      {{
                        CLUSTER_TYPE[cluster.cloudOrIdcName] && CLUSTER_TYPE[cluster.cloudOrIdcName].name
                      }}
                    </div>
                    <div>{{ getRegionName }}</div>
                  </div>
                </div>
                <Input v-model="search" class="text"
                       :placeholder="$t('qing-shu-ru-ji-qi-id-ming-cheng-cha-xun')"
                       style="width: 280px;margin-right: 10px" clearable/>
                <Button class="search-btn" type="primary" @click="handleQuery">
                  {{ $t('cha-xun') }}
                </Button>
              </div>
            </div>
            <div class="right">
              <Button v-if="myAuth.includes('DM_WORKER_MANAGE')" ghost icon="md-add"
                      style="margin-right: 10px;"
                      type="primary" @click="handleClickAddBtn">{{ $t('tian-jia-ji-qi') }}
              </Button>
              <Button class="refresh" @click="getWorkerList">{{ $t('shua-xin') }}</Button>
            </div>
          </div>
          <div class="table">
            <Table :columns="workerListColumns" :data="showWorkerList" size="small" border>
              <template slot="name" slot-scope="{ row }">
                {{ row.workerName }}
                <Poptip placement="right" transfer>
                  <cc-iconfont :size="12" name="detail"/>
                  <div slot="content" class="worker-detail">
                    <section>
                      <div class="title">{{ $t('gong-wang-xin-xi') }}:</div>
                      <div>{{ row.publicIp }}</div>
                    </section>
                    <section>
                      <div class="title">{{ $t('di-qu') }}:</div>
                      <div>{{ $t('bu-xian') }}</div>
                    </section>
                    <section>
                      <div class="title">{{ $t('chuang-jian-shi-jian') }}:</div>
                      <div>{{ row.gmtCreate | formatTime('YYYY-MM-DD HH:mm:ss') }}</div>
                    </section>
                    <section>
                      <div class="title">{{ $t('ji-qi-lei-xing') }}:</div>
                      <div>{{ $t('xu-ni-ji') }}</div>
                    </section>
                    <section>
                      <div class="title">{{ $t('ji-qi-wei-yi-biao-shi-fu') }}:</div>
                      <div style="margin-right: 5px;">{{ row.workerSeqNumber }}</div>
                      <cc-iconfont :size="12" name="copy"
                                   @click.native="copyText(`${row.workerSeqNumber}`, $t('fu-zhi-ji-qi-wei-yi-biao-shi-fu-cheng-gong'))"/>
                    </section>
                  </div>
                </Poptip>
                <cc-iconfont v-if="row.consoleTaskState==='FAILED'"
                             @click.native="handleConsoleJob(row)" name="job-error"
                             color="#FF6E0D" size="16" style="margin-left: 6px"/>
              </template>
              <template slot="desc" slot-scope="{ row }">
               {{ row.workerDesc }}
                <cc-iconfont :size="8" name="edit"
                             style="margin-right: 4px;"
                             @click.native="handleClickEditWorkerNameBtn(row)"/>
              </template>
              <template slot="ip" slot-scope="{ row }">
                {{ row.privateIp }}
              </template>
              <template slot="status" slot-scope="{ row }">
                <div
                  v-if="row.deployStatus !== 'INSTALLING'&&row.deployStatus!=='UNINSTALLING'"
                  style="display: flex">
                  <cc-status :type="HEALTH_LEVEL_COLOR[row.healthLevel]"/>
                  {{ WORKER_STATE[row.workerState].name }}
                </div>
                <div v-else-if="row.deployStatus === 'INSTALLING'">
                  <div v-if="row.consoleTaskState === 'FAILED'" style="display:flex">
                    <cc-status type="error"/>
                    {{ $t('an-zhuang-shi-bai') }}
                  </div>
                  <div v-if="row.consoleTaskState !== 'FAILED'">
                    <a-icon type="loading"/>
                    {{ $t('an-zhuang-zhong') }}
                  </div>
                </div>
                <div v-else-if="row.deployStatus === 'UNINSTALLING'">
                  <div v-if="row.consoleTaskState === 'FAILED'" style="display:flex">
                    <cc-status type="error"/>
                    {{ $t('xie-zai-shi-bai') }}
                  </div>
                  <Icon v-if="row.consoleTaskState !== 'FAILED'" type="ios-loading" />
                  {{ $t('xie-zai-zhong') }}
                </div>
              </template>
              <template slot="actions" slot-scope="{ row }">
                <div class="work-list-table-actions">
                  <Button :disabled="!canStart(row)" size="small"
                            type="text" v-if="myAuth.includes('DM_WORKER_MANAGE')"
                            @click="handleAction('start', row)">{{ $t('qi-dong') }}
                  </Button>
                  <Button :disabled="!canStop(row)" size="small"
                            v-if="myAuth.includes('DM_WORKER_MANAGE')"
                            type="text"
                            @click="handleAction('stop', row)">{{ $t('ting-zhi') }}
                  </Button>
                  <div v-if="row.cloudOrIdcName === CLUSTER_ENV.SELF_MAINTENANCE">
                    <Button size="small" type="text"
                              @click="handleAction('conf', row)">
                      {{ $t('cha-kan-pei-zhi-wen-jian') }}
                    </Button>
                  </div>
                  <div v-else>
                    <Button :disabled="!canInstall(row)"
                              v-if="myAuth.includes('DM_WORKER_MANAGE')"
                              size="small" type="text"
                              @click="handleAction('install', row)">
                      {{ $t('an-zhuang') }}
                    </Button>
                    <Button :disabled="!canUpdate(row)" size="small"
                              v-if="myAuth.includes('DM_WORKER_MANAGE')"
                              type="text"
                              @click="handleAction('update', row)">
                      {{ $t('geng-xin') }}
                    </Button>
                    <Button :disabled="!canUninstall(row)"
                              v-if="myAuth.includes('DM_WORKER_MANAGE')"
                              size="small"
                              type="text"
                              @click="handleAction('uninstall', row)">{{ $t('xie-zai') }}
                    </Button>
                  </div>
                  <Poptip :disabled="!canDelete(row)"
                          confirm
                          transfer
                          v-if="myAuth.includes('DM_WORKER_MANAGE')"
                          :title="$t('que-ding-shan-chu-gai-ji-qi-ma')"
                          @on-ok="handleAction('delete', row)">
                    <Button :disabled="!canDelete(row)" size="small" type="text">
                      {{$t('shan-chu') }}
                    </Button>
                  </Poptip>
                </div>
              </template>
            </Table>
          </div>
        </div>
      </div>
      <div class="footer">
        <Page :total="total" show-total show-elevator @on-change="handlePageChange"
              show-sizer
              v-model="pageNum"
              :page-size="pageSize" @on-page-size-change="handlePageSizeChange"/>
      </div>
    </div>
    <a-modal
      v-model="showAddWorkerModal"
      :mask-closable="false"
      :width="1240"
      :title="$t('tian-jia-ji-qi')"
    >
      <add-machine-modal v-if="showAddWorkerModal" :clusterId="parseInt(clusterId, 10)"
                         :handleAddWorker="handleAddWorker"
                         :handleCancelAddWorker="handleCancelAddWorker"/>
    </a-modal>
    <a-modal v-model="showDownloadModal" :mask-closable="false" :width="620"
             :title="$t('xia-zai-ke-hu-duan')">
      <div class="download-modal">
        <a-alert banner
                 :message="$t('xia-zai-lian-jie-1-xiao-shi-nei-you-xiao-qing-jin-kuai-xia-zai')"
                 style="margin-bottom: 10px;"/>
        <div class="url">
          {{ downloadUrl }}
        </div>
        <div class="btn-group">
          <a-button type="primary" @click="handleDownload">{{ $t('xia-zai') }}</a-button>
          <a-button type="primary"
                    @click="copyText(downloadUrl, $t('fu-zhi-xia-zai-lian-jie-cheng-gong'))">
            {{ $t('fu-zhi-lian-jie') }}
          </a-button>
          <a-button @click="handleCancelDownload">{{ $t('qu-xiao') }}</a-button>
        </div>
      </div>
    </a-modal>
    <a-modal v-model="showConfigModal" :mask-closable="false" :width="630"
             :title="$t('pei-zhi-wen-jian')">
      <div v-if="showConfigModal" class="config-modal">
        <a-alert banner
                 :message="$t('qing-jiang-xia-lie-pei-zhi-wan-zheng-fu-zhi-dao-clouddm-an-zhuang-mu-lu-xia-de-confglobalconfproperties-wen-jian-zhong')"
                 style="margin-bottom: 10px;"/>
        <div class="config">
          <div>{{ workerConfig.userAkLabel }}={{ workerConfig.userAkValue }}</div>
          <div>{{ workerConfig.userSkLabel }}={{ workerConfig.userSkValue }}</div>
          <div>{{ workerConfig.wsnLabel }}={{ workerConfig.wsnValue }}</div>
          <div>{{ workerConfig.consoleHostLabel }}={{ workerConfig.consoleHostValue }}</div>
          <div>{{ workerConfig.consolePortLabel }}={{ workerConfig.consolePortValue }}</div>
        </div>
        <div class="btn-group">
          <a-button type="primary" @click="copyConfig">{{ $t('fu-zhi') }}</a-button>
          <a-button @click="showConfigModal=false">{{ $t('guan-bi') }}</a-button>
        </div>
      </div>
    </a-modal>
    <a-modal v-model="showEditWorkerNameModal" :mask-closable="false" :width="400"
             :cancelText="$t('qu-xiao')"
             :okText="$t('bao-cun')" :title="$t('xiu-gai-ming-cheng')" wrapClassName="have-footer"
             @ok="handleUpdateWorkerDesc">
      <a-input v-model="selectedWorker.workerDesc" type="textarea"/>
    </a-modal>
    <a-modal v-model="showGetConfigSms" :mask-closable="false" :width="370" :title="$t('huo-qu-yan-zheng-ma')">
      <div class="sms-modal">
        <div>{{ $t('huo-qu-pei-zhi-wen-jian-xu-duan-xin-yan-zheng') }}</div>
        <div class="code">
          <a-input style="margin-right:6px" v-model="smsCode"/>
          <cc-sms-button login :verifyType="VERIFY_TYPE.SMS"
                         :verifyCodeType="VERIFY_CODE_TYPE.FETCH_WORKER_DEPLOY_CORE_CONFIG"/>
        </div>
        <div class="btn-group">
          <a-button type="primary" @click="getWorkerConfig(selectedWorker)">{{
              $t('cha-kan')
            }}
          </a-button>
          <a-button @click="hideSmsModal">{{ $t('guan-bi') }}</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script lang="js">
import cloneDeep from 'lodash.clonedeep';
import AddMachineModal from '@/views/system/cluster/components/AddMachineModal';
// import { Modal } from 'view-design';
import { Modal } from 'ant-design-vue';
import {
  CLUSTER_ENV,
  CLUSTER_TYPE,
  DEPLOY_STATUS,
  HEALTH_LEVEL_COLOR,
  VERIFY_CODE_TYPE,
  VERIFY_TYPE,
  WORKER_STATE
} from '@/const';
import { mapState } from 'vuex';
import copyMixin from '@/mixins/copyMixin';

export default {
  name: 'WorkerList',
  components: {
    AddMachineModal
  },
  mixins: [copyMixin],
  data() {
    return {
      VERIFY_CODE_TYPE,
      VERIFY_TYPE,
      CLUSTER_TYPE,
      cluster: {},
      DEPLOY_STATUS,
      WORKER_STATE,
      HEALTH_LEVEL_COLOR,
      CLUSTER_ENV,
      countDown: 60,
      codeBtnMsg: this.$t('huo-qu-yan-zheng-ma'),
      timer: null,
      smsCode: '',
      clusterId: '',
      downloadUrl: '',
      search: '',
      workerConfig: {
        consoleHostLabel: '',
        consoleHostValue: '',
        consolePortLabel: '',
        consolePortValue: '',
        userAkLabel: '',
        userAkValue: '',
        userSkLabel: '',
        userSkValue: '',
        wsnLabel: '',
        wsnValue: ''
      },
      showConfModal: false,
      showAddWorkerModal: false,
      showConfigModal: false,
      showDownloadModal: false,
      pageSize: 20,
      pageNum: 1,
      total: 0,
      workerListColumns: [
        {
          title: this.$t('ji-qi-id'),
          minWidth: 200,
          slot: 'name'
        }, {
          title: '机器名称',
          minWidth: 200,
          slot: 'desc'
        }, {
          title: this.$t('ji-qi-ip'),
          width: 150,
          slot: 'ip'
        }, {
          title: this.$t('zhuang-tai'),
          width: 100,
          slot: 'status'
        }, {
          title: this.$t('fu-zai'),
          width: 100,
          key: 'workerLoad'
        }, {
          title: this.$t('cpu-shi-yong-shuai'),
          width: 150,
          key: 'cpuUseRatio'
        }, {
          title: this.$t('nei-cun-shi-yong-shuai'),
          width: 150,
          key: 'memUseRatio'
        },
        {
          title: this.$t('yi-shi-yong-hui-hua-shu'),
          width: 150,
          key: 'sessionPoolUse'
        },
        {
          title: this.$t('zui-da-hui-hua-shu'),
          width: 100,
          key: 'sessionPoolMax'
        },
        {
          title: this.$t('cao-zuo'),
          width: 300,
          key: 'action',
          fixed: 'right',
          slot: 'actions'
        }
      ],
      allWorkerList: [],
      workerList: [],
      showWorkerList: [],
      selectedWorker: {},
      showEditWorkerNameModal: false,
      showGetConfigSms: false,
      regions: []
    };
  },
  computed: {
    ...mapState(['userInfo', 'regionList', 'myAuth']),
    getRegionName() {
      let regionName = '';
      this.regions.forEach((region) => {
        if (region.region === this.cluster.region) {
          regionName = region.i18nName;
        }
      });
      return regionName;
    }
  },
  methods: {
    hideConfModal() {
      this.showConfModal = false;
    },
    listDmRegions() {
      this.$services.listRegions()
        .then((res) => {
          if (res.success) {
            this.regions = res.data;
          }
        });
    },
    async queryClusterById() {
      const res = await this.$services.getCluster({ data: { clusterId: this.clusterId } });
      if (res.success) {
        this.cluster = res.data;
      }
    },
    handleClickAddBtn() {
      this.showAddWorkerModal = true;
    },
    canStart(worker) {
      return (worker.workerState === 'OFFLINE' || worker.workerState === 'WAIT_TO_OFFLINE')
        && ((worker.deployStatus === 'INSTALLED' && worker.cloudOrIdcName !== 'SELF_MAINTENANCE') || worker.cloudOrIdcName === 'SELF_MAINTENANCE');
    },
    canStop(worker) {
      return worker.workerState === 'ONLINE' || worker.workerState === 'WAIT_TO_ONLINE' || worker.workerState === 'ABNORMAL';
    },
    canInstall(worker) {
      return (worker.workerState === 'OFFLINE'
        && (worker.deployStatus === 'UNINSTALLED' || worker.deployStatus === '')
        || ((worker.workerState === 'WAIT_TO_OFFLINE' && worker.deployStatus === 'UNINSTALLED')));
    },
    canUpdate(worker) {
      return worker.deployStatus === 'INSTALLED';
    },
    canUninstall(worker) {
      return (worker.workerState === 'WAIT_TO_OFFLINE' || worker.workerState === 'OFFLINE') && worker.deployStatus === 'INSTALLED';
    },
    canDelete(worker) {
      return (worker.workerState === 'OFFLINE' || worker.workerState === 'WAIT_TO_OFFLINE')
        && (worker.deployStatus === 'UNINSTALLED' && worker.cloudOrIdcName !== 'SELF_MAINTENANCE' || worker.cloudOrIdcName === 'SELF_MAINTENANCE')
        || (worker.cloudOrIdcName === 'SELF_MAINTENANCE' && !worker.privateIp) || worker.healthLevel !== 'Health';
    },
    handleClickEditWorkerNameBtn(worker) {
      this.selectedWorker = cloneDeep(worker);
      this.showEditWorkerNameModal = true;
    },
    async handleUpdateWorkerDesc() {
      const {
        id,
        workerDesc
      } = this.selectedWorker;
      const data = {
        desc: workerDesc,
        workerId: id
      };
      const res = await this.$services.updateWorkerDesc({
        data,
        msg: this.$t('xiu-gai-ji-qi-ming-cheng-cheng-gong')
      });

      if (res.success) {
        this.getWorkerList();
        this.showEditWorkerNameModal = false;
      }
    },
    copyConfig() {
      const {
        consoleHostLabel,
        consoleHostValue,
        consolePortLabel,
        consolePortValue,
        userAkLabel,
        userAkValue,
        userSkLabel,
        userSkValue,
        wsnLabel,
        wsnValue
      } = this.workerConfig;
      const value = `
      ${userAkLabel}=${userAkValue}\n
      ${userSkLabel}=${userSkValue}\n
      ${wsnLabel}=${wsnValue}\n
      ${consoleHostLabel}=${consoleHostValue}\n
      ${consolePortLabel}=${consolePortValue}
      `;
      this.copyText(value, this.$t('pei-zhi-wen-jian-fu-zhi-cheng-gong'));
    },
    handleQuery() {
      const workerList = this.allWorkerList.filter((worker) => worker.workerName.includes(this.search) || worker.workerDesc.includes(this.search));
      this.total = workerList.length;
      this.workerList = workerList;
      this.handlePageSizeChange(this.pageSize);
    },
    handleCancelDownload() {
      this.showDownloadModal = false;
    },
    async handleDownload() {
      window.open(this.downloadUrl);
    },
    async handleAddWorker(data = {}) {
      const res = await this.$services.createWorker({
        data: {
          ...data,
          clusterId: this.clusterId,
          region: 'customer'
        },
        msg: this.$t('sheng-cheng-ji-qi-biao-shi-fu-cheng-gong')
      });

      if (res.success) {
        this.showAddWorkerModal = false;
        await this.getWorkerList();
      }
    },
    async getWorkerList() {
      const data = {
        clusterId: this.clusterId
      };
      const res = await this.$services.getWorkerList({ data });
      if (res.success) {
        this.workerList = res.data;
        this.allWorkerList = res.data;
        this.total = res.data.length;
        this.setTableShowData();
      }
    },
    handlePageChange(pageNum) {
      this.pageNum = pageNum;
      this.setTableShowData();
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.handlePageChange(1);
    },
    setTableShowData() {
      const { pageNum, pageSize } = this;
      this.showWorkerList = this.workerList.slice((pageNum - 1) * pageSize, pageNum * pageSize);
    },
    handleCancelAddWorker() {
      this.showAddWorkerModal = false;
      this.getWorkerList();
    },
    async handleAction(action, worker) {
      let res;
      const { id: workerId } = worker;
      const data = {
        clusterId: this.clusterId
      };
      if (['install', 'uninstall', 'update'].includes(action)) {
        data.workerIds = [workerId];
      } else {
        data.workerId = workerId;
      }
      switch (action) {
        case 'install':
          res = await this.$services.installAliyunEcs({
            data,
            msg: this.$t('ji-qi-an-zhuang-cheng-gong')
          });
          if (res.success) {
            this.getWorkerList();
          }
          break;
        case 'uninstall':
          this.workerList.forEach((w) => {
            if (w.id === workerId) {
              w.deployStatus = 'UNINSTALLING';
            }
          });
          this.workerList = [...this.workerList];
          res = await this.$services.uninstallAliyunEcs({
            data,
            msg: this.$t('ji-qi-xie-zai-cheng-gong')
          });
          // if (res.success) {
          //   this.getWorkerList();
          // }
          break;
        case 'update':
          res = await this.$services.upgradeAliyunEcs({
            data,
            msg: this.$t('ji-qi-geng-xin-cheng-gong')
          });
          if (res.success) {
            this.getWorkerList();
          }
          break;
        case 'start':
          res = await this.$services.startWorker({
            data,
            msg: this.$t('ji-qi-qi-dong-cheng-gong')
          });
          if (res.success) {
            this.getWorkerList();
          }
          break;
        case 'stop':
          res = await this.$services.stopWorker({
            data,
            msg: this.$t('ji-qi-ting-zhi-cheng-gong')
          });

          if (res.success) {
            this.getWorkerList();
          }
          break;
        case 'download':
          res = await this.$services.getDownloadUrl({ data });
          if (res.success) {
            this.downloadUrl = res.data;
            this.showDownloadModal = true;
          }
          break;
        case 'delete':
          res = await this.$services.deleteWorker({ data });
          if (res.success) {
            this.getWorkerList();
          }
          break;
        case 'conf':
          this.selectedWorker = data;
          this.showGetConfigSms = true;
          // Modal.confirm({
          //   title: this.$t('pei-zhi-wen-jian-yan-zheng'),
          //   width: 370,
          //   okText: this.$t('que-ding'),
          //   cancelText: this.$t('qu-xiao'),
          //   onOk: () => this.getWorkerConfig(data),
          //   onCancel: () => this.hideSmsModal(),
          //   content: () => (
          //     <div class="sms-modal">
          //       <div>{ this.$t('huo-qu-pei-zhi-wen-jian-xu-duan-xin-yan-zheng') }</div>
          //       <div class="code">
          //         <a-input style="margin-right:6px" v-model={this.smsCode}/>
          //         <cc-sms-button login verifyType={VERIFY_TYPE.SMS}
          //                        verifyCodeType={VERIFY_CODE_TYPE.FETCH_WORKER_DEPLOY_CORE_CONFIG}/>
          //       </div>
          //     </div>
          //   )
          // });
          break;
        default:
          return false;
      }
    },
    hideSmsModal() {
      this.showGetConfigSms = false;
      this.smsCode = '';
    },
    async getWorkerConfig(data = {}) {
      data.verifyCode = this.smsCode;
      const res = await this.$services.getWorkerConfig({ data });
      if (res.success) {
        Modal.destroyAll();
        this.workerConfig = res.data;
        this.showConfigModal = true;
        this.smsCode = '';
      }
    },
    handleConsoleJob(record) {
      this.$router.push({ path: `/system/console_job/${record.consoleJobId}` });
    }
  },
  created() {
    this.clusterId = this.$route.params.clusterId;
    this.queryClusterById();
    this.getWorkerList();
    this.listDmRegions();
  }
};
</script>

<style lang="less" scoped>
.machine-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  .machine {
    display: flex;
    align-items: center;

    .circle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: rgba(255, 193, 92, 1);
      box-shadow: 0px 3px 12px 0px rgba(255, 192, 92, 1);
      text-align: center;
      line-height: 40px;
      margin-left: 20px;
    }

    .content {
      display: flex;
      flex-direction: column;
      margin: 0 10px;

      & > div {
        display: flex;
      }

      .first {
        display: flex;
        align-items: center;
        margin-bottom: 6px;

        & > div:first-child {
          font-size: 14px;
          font-weight: bold;
          margin-right: 10px;
        }
      }

      .second {
        & > div {
          border-radius: 14px;
          padding: 0 5px;
          background-color: rgba(224, 224, 224, 1);

          &:first-child {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.worker-detail {
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  section {
    margin-left: 10px;
    width: 250px;
    display: flex;
    margin-bottom: 5px;

    .title {
      font-weight: bold;
    }
  }
}

.download-modal {
  .url {
    padding: 10px;
    border: 1px solid #EDEDED;
    background: #FAFAFA;
    word-wrap: break-word;
    margin-bottom: 20px;
  }

  .btn-group {
    display: flex;
    justify-content: center;

    button + button {
      margin-left: 10px;
    }
  }
}

.sms-modal {
  .code {
    display: flex;
    margin-top: 10px;
  }

  .btn-group {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    button + button {
      margin-left: 10px;
    }
  }
}

.config-modal {
  .config {
    border: 1px solid #EDEDED;
    background: #FAFAFA;
    padding: 10px;
  }

  .btn-group {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button + button {
      margin-left: 10px;
    }
  }
}

.work-list-table-actions {
  display: flex;
}

.worker-detail {
  display: flex;
  flex-wrap: wrap;
  width: 600px;

  section {
    display: flex;
    height: 20px;
    line-height: 20px;

    .title {
      color: #888;
    }

    div:last-child {
      font-weight: bold;
    }

    &:not(:last-child) {
      width: 50%;
    }
  }
}
</style>
